@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:wght@300;400;500;600;700;800;900&display=swap");

body {
  font-family: "Lucida Grande", Tahoma, Verdana, Arial, sans-serif !important;
  background-color: #f3f3f3 !important;
}

.border-radius-32 {
  border-radius: 32px !important;
}

.border-radius-100 {
  border-radius: 100px !important;
}

.card-login {
  width: 600px;
  padding: 18px 56px 35px 60px;
  box-shadow: 0px 2px 5px 0px rgba(152, 152, 152, 0.5);
  border: none !important;
}

.btn-create-default {
  background-color: #fff !important;
  color: #333333 !important;
  border-color: #333333 !important;
  border-radius: 6px;
}

.btn-development-default {
  background-color: #fff !important; 
  color: #32ab6d !important;
  border-color: #32ab6d !important;
  border-radius: 5px;
}

.btn-departure-default {
  background-color:  #fff !important; 
  color: #ca3d42 !important;
  border-color: #ca3d42 !important;
  border-radius: 5px;
}

.btn-create-default:hover {
  background-color: #333333 !important;
  color: #fff !important;
}

.icon-rejected {
  color:#ca3d42 !important;
}

.text-rejected {
  color:#777 !important;
}

.btn-create-primary {
  border-radius: 6px;
}

.btn-create-primary:hover {
  background-color: #32ab6d !important;
  color: #fff !important;
}

.btn-save-primary {
  width: 103px;
  border-radius: 6px;
}

.btn-next-departure {
  width: 122px;
  border-radius: 6px;
}

.btn-cancel-danger {
  background-color: #f5d9d6;
  color: #d8675a;
  border: none;
  width: 103px;
  border-radius: 6px;
}

.btn-cancel-danger:hover {
  color: #fff !important;
  background-color: #ff3e3e !important;
}

.btn-color-green {
  background-color: #b0e8cb !important;
  color: #32ab6d !important;
  border: none;
  border-radius: 6px;
}

.btn-color-green:hover {
  background-color: #32ab6d !important;
  color: #fff !important;
}

.btn-color-red {
  background-color: #f5d9d6 !important;
  color: #d8675a !important;
  border: none;
  border-radius: 6px;
}

.btn-color-red:hover {
  background-color: #d8675a !important;
  color: #fff !important;
}

.btn-color-blue {
  background-color: #bfddf7 !important;
  color: #0076de !important;
  border: none;
  border-radius: 6px;
}

.btn-color-blue:hover {
  background-color: #0076de !important;
  color: #fff !important;
}

.btn-profile {
  border-color: #1881E0 !important;
  background-color: #1881E0 !important;
  color: #fff !important;
  border-radius: 6px;
}

.btn-profile:hover {
  border-color: #1674CA !important;
  background-color: #1674CA !important;
  color: #fff !important;
  border-radius: 6px;
}

.btn-more {
  width: 32px;
  padding: 4px;
  border-radius: 6px;
  border-color: #d9d9d9 !important;
  color: rgba(0, 0, 0, 0.85) !important;
}

.btn-more:hover {
  border-color: #0076de !important;
  color: #0076de !important;
}

.btn-radius {
  border-radius: 6px;
}

.btn-edit-candidate {
  background-color: rgba(50, 171, 109, 0.07);
  border-radius: 6px;
  color: #32ab6d;
  width: 80px;
}

.icon-close-delete:hover {
  color: #ff3e3e !important;
}

.row-pointer {
  cursor: pointer;
}

.ant-card {
  border-radius: 6px;
  border: solid 1px #979797;
}

.ant-btn-danger {
  background: #ca3d42;
}

.ant-input {
  border-radius: 6px;
}

.ant-input-number {
  border-radius: 6px;
  width: 100%;
}

.ant-picker {
  border-radius: 6px;
}

.ant-select {
  border-radius: 6px;
}

.ant-select-selector {
  border-radius: 6px !important;
}

.ant-input-group-addon {
  border: none;
}

.ant-input-affix-wrapper {
  border-radius: 6px;
}

.ant-descriptions-item-label {
  color: #7b7b7b;
}

.ant-collapse-item {
  background-color: #fff;
}

.prefix-selector {
  border-radius: 0px;
}

.link {
  color: #0076de !important;
}

.text-align-center {
  text-align: center !important;
}

.ql-container.ql-snow {
  padding-bottom: 70px;
}

.ant-descriptions-item-container {
  width: 80%;
}

.ant-input-group-addon
  .ant-select.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  width: 100px;
  border-radius: 6px 0 0 6px !important;
  background-color: #fff;
  border: 1px solid #d9d9d9;
}

.site-page-header {
  padding: 0px;
}

.demo-infinite-container {
  height: 600px;
  padding: 8px 13px;
  overflow: auto;
  border: 1px solid #979797;
  border-radius: 4px;
}

.demo-infinite-notifcation {
  height: 100%;
  overflow: auto;
}

.link-dashboard {
  color: #717171 !important;
}

.link-dashboard:hover {
  color: #0076de !important;
  text-decoration: underline !important;
}

.link-dashboard-phase:hover {
  font-weight: bolder !important;
  text-decoration: underline !important;
}

.ant-radio-button-wrapper {
  border: 1px solid #32ab6d !important;
}

.list-slider-photo {
  display: flex;
  overflow-x: auto;
}

.cover-photo {
  cursor: pointer;
  position: relative;
  text-align: center;
  background-color: #f0eded;
  box-shadow: 0 2px 4px 0 rgba(198, 198, 198, 0.5);
  border: none;
  margin-right: 30px;
}

.not-cover-photo {
  cursor: pointer;
  position: relative;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(198, 198, 198, 0.5);
  border: none;
  margin-right: 30px;
}

.ant-statistic-content {
  font-size: 17px !important;
  color: #ca3d42;
}

.ant-dropdown-menu {
  max-height: 400px;
  overflow: auto;
}

.menu-fixed {
  text-align: center;
}

.dynamic-delete-button {
  position: relative;
  top: 4px;
  margin-top: -55px;
  margin-right: 24px;
  color: #999;
  font-size: 24px;
  cursor: pointer;
  transition: all 0.3s;
  display: flex;
  float: right;
}

.dynamic-delete-button2 {
  position: relative;
  margin: auto;
  color: #999;
  font-size: 24px;
  cursor: pointer;
  transition: all 0.3s;
  display: flex;
  float: right;
}

.ticket-dynamic-delete-button {
  position: relative;
  top: 4px;
  margin-top: -55px;
  margin-right: 12px;
  color: #F58181;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.3s;
  display: flex;
  float: right;
}

.dynamic-delete-button:hover {
  color: #777;
}

.ticket-dynamic-delete-button:hover {
  color: #f25a5a;
}

.fs-10 {
  font-size: 10px !important;
}

.fs-15 {
  font-size: 15 !important;
}

.fs-17 {
  font-size: 17px !important;
}

.pl-100 {
  padding-left: 100px !important;
}

.pl-40 {
  padding-left: 40px !important;
}

.pt-16 {
  padding-top: 16px !important;
}

.ls {
  letter-spacing: 0.01px !important;
}

.frame::-webkit-scrollbar {
  -webkit-appearance: none;
}

.frame::-webkit-scrollbar:vertical {
  width: 11px;
}

.frame::-webkit-scrollbar:horizontal {
  height: 11px;
}

.frame::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white; /* should match background, can't be transparent */
  background-color: rgba(0, 0, 0, .5);
}

.upload-job-story > .ant-upload-list {
  display: inline-flex;
}

.upload-video-job-story > .ant-upload-list{
  display: inline-flex ;
}

.card-border{
  padding: 24px;
  background-color: "#f2f2f2";
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

@media print {
  @page {
      margin: 20mm;
  }
}